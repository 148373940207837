import { eventNameConstants, trackEvent, stepInteractionConstants } from 'dibs-tracking';
const { EVENT_PROMO_INTERACTION, EVENT_WISHLIST } = eventNameConstants;
const { STEP_INTERACTION_LINK_CLICK } = stepInteractionConstants;

export const trackNullResultsDisplayed = (): void => {
    trackEvent({
        category: 'null results module interaction',
        action: 'null modules displayed',
        label: 'none',
        value: 0,
    });
};

export const trackNullResultsFollowSearch = (): void => {
    const category = 'search favoriting';
    const action = 'search added';
    trackEvent({
        category,
        eventName: EVENT_WISHLIST,
        action,
        interaction_type: category,
        label: 'null results',
        step_interaction_name: action,
        value: 0,
    });
};

export const trackL1ModuleClick = (l1Name: string): void => {
    const stepType = l1Name.toLowerCase();
    trackEvent({
        category: 'null results module interaction',
        action: 'l1 link clicked',
        label: stepType,
        value: 0,
        eventName: EVENT_PROMO_INTERACTION,
        step_interaction_name: STEP_INTERACTION_LINK_CLICK,
        step_type: stepType,
        trigger: 'null results page',
    });
};

export const trackL2ModuleClick = (l1Name: string, primary: boolean): void => {
    const description = primary ? 'primary' : 'secondary';
    const stepType = `${l1Name.toLowerCase()} | ${description} l2`;
    trackEvent({
        category: 'null results module interaction',
        action: 'l2 link clicked',
        label: stepType,
        value: 0,
        eventName: EVENT_PROMO_INTERACTION,
        step_interaction_name: STEP_INTERACTION_LINK_CLICK,
        step_type: stepType,
        trigger: 'null results page',
    });
};

export const trackCreatorModuleClick = (creatorName: string): void => {
    trackEvent({
        category: 'null results module interaction',
        action: 'creator link click',
        label: creatorName,
        value: 0,
        eventName: EVENT_PROMO_INTERACTION,
        step_interaction_name: STEP_INTERACTION_LINK_CLICK,
        step_type: creatorName,
        trigger: 'null results page',
    });
};

export const trackRoomModuleClick = (roomName: string): void => {
    trackEvent({
        category: 'null results module interaction',
        action: 'room link click',
        label: roomName.toLowerCase(),
        value: 0,
    });
};

export const trackModuleClick = (actionLabel: string, index: number): void => {
    trackEvent({
        category: 'null results module interaction',
        action: `${actionLabel} clicked`,
        label: index + 1, // Tracking needs non zero index position.
        value: 0,
        noninteraction: 0,
    });
};
